import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { mediaMax } from "../../utils/breakpoints";

interface RecordProps{
  speakerLinks?: any,
  sideSpeakerLinks?: any
}

const VideoRoom = ({ speakerLinks , sideSpeakerLinks} : RecordProps) => {
  const data = useStaticQuery(
    graphql`
      query {
        Background: file(relativePath: { eq: "Marel-dagskrá-bakgrunnur.jpeg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const [lectureHref, setLectureHref] = useState("");
  const withLinkList = speakerLinks != null ? speakerLinks : [];
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const hashhref = location.hash;
    console.log(withLinkList)
    const findHref = withLinkList.filter(item => item.slug == hashhref.substring(1));
    if(findHref.length != 0){
      setLectureHref(findHref[0].link)
    }
   
    setIsLoading(false);
  },[withLinkList])

  return (
    <StyledContainer>
      <StyledBackgroundImage>
        <Img fluid={data.Background.childImageSharp.fluid} style={{ height: "100%" }}/>
      </StyledBackgroundImage>
      <StyledVideoContainer>
        {!isLoading && 
          <>
            {lectureHref != "" ? (
              <iframe
                src={lectureHref}
                width="50%"
                height="50%"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : (
              <NotFoundText>Fyrirlestur finnst ekki!</NotFoundText>
            )
            }            
          </>
        }
      </StyledVideoContainer>
    </StyledContainer>
  )
}

const StyledBackgroundImage = styled.div`
  height: calc(100vh + 50px) !important;
  width: calc(100vw + 50px) !important;
  position: absolute;
  left: -25px !important;
  top: -25px !important;
  transform: scale(1.3);
  transition: opacity 0s !important;

  img {
    transition: opacity 0s !important;
    opacity: 1 !important;
  }
`

const NotFoundText = styled.p`
  color: white;
  font-size: 35px;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
`

const StyledVideoContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;

  @media ${mediaMax.tabletL} {
    > iframe {
      height: 85% !important;
      width: 85% !important;
    }
  }

  @media ${mediaMax.mobileL} {
    > iframe {
      width: 100% !important;
    }
  }
`

const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`

export default VideoRoom
