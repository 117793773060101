  
import React, { useContext } from 'react';
import Layout, { MyLocationEnum } from '../components/Layout'
import RecordedLecture from '../components/RecordedLectures/RecordedLecture';
import DynamicContext from '../context'
import SEO, { MetaOG } from "../components/Seo"

const records = ({ location }) => {

  const seo_og: MetaOG = {
    title: "Upptökur",
    description: "Upptökur af fyrirlestrum",
  }

  const { 
    speakerLinks,
  } = useContext(DynamicContext)

  return (
    <Layout location={location} myLocation={MyLocationEnum.Records}>
       <SEO
        title="Upptökur"
        description="Upptökur af fyrirlestrum"
        og={seo_og}
      />

      <RecordedLecture speakerLinks={speakerLinks}></RecordedLecture>
    </Layout>
  )
}

export default records;